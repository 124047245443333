<template>
  <b-container class="pt-5">
    <b-row>
      <b-col sm="12" md="12" lg="8" class="offset-lg-2 mb-3 tours__list">
        <b-table
          responsive
          ref="table"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="0"
          :busy.sync="isBusy"
        >
          <template v-slot:cell(date)="data">
            <small
              class="text-white pr-3 text-nowrap"
              v-if="$i18n.locale === 'ru'"
              >{{ data.item.date }}</small
            >
            <small class="text-white pr-3 text-nowrap" v-else>{{
              data.item.date_eng
            }}</small>
            <small class="text-white">{{ data.item.time_start }}</small>
          </template>
          <template v-slot:cell(title)="data">
            <router-link
              :to="'/rocket-league/tour/' + data.item.id"
              class="text-white"
            >
              <small class="text-uppercase" v-if="$i18n.locale === 'ru'">{{
                data.item.title
              }}</small>
              <small class="text-uppercase" v-else>{{
                data.item.title_eng
              }}</small>
            </router-link>
          </template>
          <template v-slot:cell(teams-col)="data">
            <span v-for="team in data.item.teams" :key="team.ID" class="pr-2">
              <img
                :src="team.filetime ? ($url+'upload/images/teams/'+ team.id_team_sl +'/100x100.png?v='+ team.filetime) : '/img/icons/stub.png'"
                :id="'popover-' + team.id_team_sl + '-' + data.item.id"
                width="28"
                height="28"
                class="hover-img rounded-sm"
                variant="primary"
              />

              <b-popover
                :target="'popover-' + team.id_team_sl + '-' + data.item.id"
                placement="top"
                triggers="hover focus"
              >
                <template v-if="$i18n.locale === 'ru'" v-slot:title>{{
                  team.team_title
                }}</template>
                <template v-else v-slot:title>{{
                  team.team_title_eng
                }}</template>
                <span v-if="$i18n.locale === 'ru'">
                  {{ team.player_1_title }}, {{ team.player_2_title }}
                </span>
                <span v-else
                  >{{ team.player_1_title_eng }},
                  {{ team.player_2_title_eng }}</span
                >
                <p v-if="!team.filetime">
                  <span v-if="$i18n.locale === 'ru'"
                    >Картинка команды не найдена</span
                  >
                  <span v-else>No team image found</span>
                </p>
              </b-popover>
            </span>
          </template>
          <template v-slot:cell(status)="data" class="text-right">
            <span v-if="data.item.status === 'live'" class="fifa-text-color">{{
              data.item.status
            }}</span>
            <span v-else
              ><small class="text-white">{{ data.item.status }}</small></span
            >
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12" lg="4" class="offset-lg-4 mb-4">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="rl"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "rocket_league_tours",
  data() {
    return {
      isBusy: false,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 30]
    };
  },
  mounted() {
    this.getApi(this.$url + "api/rl/tours/load/all");
    const self = this;

    this.fields.forEach(function(elem) {
      if (elem.key === "date") {
        elem.label = self.$t("other.date_and_time");
      } else if (elem.key === "title") {
        elem.label = self.$t("other.tour");
      } else if (elem.key === "teams-col") {
        elem.label = self.$t("other.teams");
      } else if (elem.key === "status") {
        elem.label = self.$t("other.status");
      }
    });
  },
  methods: {
    getApi(url) {
      this.isBusy = true;

      const params = new URLSearchParams();
      params.append("perPage", this.perPage);
      params.append("currentPage", this.currentPage - 1);

      this.$http.post(url, params).then(response => {
        this.items = response.data.tours;
        this.totalRows = response.data.totalRows;
        this.isBusy = false;
        const pageFromStorage = +sessionStorage.getItem("rlToursListPage");
        if (pageFromStorage)
          setTimeout(() => (this.currentPage = pageFromStorage), 10);
      });
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    }
  },
  metaInfo() {
    return {
      title: this.$t("meta.sports.rl.tours.title"),
      titleTemplate: "H2H Liga | %s",
      meta: [
        {
          name: "description",
          content: this.$t("meta.sports.rl.tours.description")
        }
      ]
    };
  },
  computed: {
    fields() {
      // Note 'isActive' is left out and will not appear in the rendered table
      return [
        {
          key: "date",
          thClass: ["rl-text-color", "th-date"],
          label: this.$t("other.date_and_time"),
          sortable: false
        },
        {
          key: "title",
          thClass: ["rl-text-color", "th-title"],
          label: this.$t("other.tour"),
          sortable: false
        },
        {
          key: "teams-col",
          thClass: ["rl-text-color", "th-teams"],
          label: this.$t("other.teams"),
          sortable: false
        },
        {
          key: "status",
          thClass: ["rl-text-color", "th-status", "text-right"],
          tdClass: "text-right",
          label: this.$t("other.status"),
          sortable: false
        }
      ];
    }
  },
  watch: {
    currentPage() {
      this.getApi(this.$url + "api/rl/tours/load/all");
      sessionStorage.setItem("rlToursListPage", this.currentPage);
    }
  }
};
</script>

<style>
.table thead th {
  vertical-align: bottom;
  border-bottom: 0px !important;
  border-top: 0px !important;
  font-weight: normal;
}

.table td,
.table th {
  border-top: 1px solid rgb(222 226 230 / 11%);
}

.th-date,
.th-title {
  width: 250px;
  min-width: 150px;
}

.th-teams {
  width: 320px;
  min-width: 170px;
}

.th-status {
  width: 140px;
  min-width: 100px;
}

.hover-img {
  cursor: help;
}
.tours__list {
  min-height: 600px;
}
.page-item.disabled .page-link {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.1);
}
.rl .page-item.active .page-link {
  color: #000000 !important;
  background-color: #ff3fe0 !important;
  border-color: #ff3fe0 !important;
}
.page-link {
  color: #6b6b6b;
  background-color: rgba(47, 47, 47, 0.6);
  border-color: rgba(255, 255, 255, 0.1);
}
.page-link:hover {
  color: #6b6b6b;
  background-color: rgba(47, 47, 47, 0.8);
  border-color: rgba(255, 255, 255, 0.1);
}
</style>
